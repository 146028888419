import {mapActions, mapGetters, mapMutations} from "vuex";

//sections
import catalogSection from "@/modules/catalog/section/catalog-section/index.vue";
import {dynamicHead} from "../../mixins/dynamic-head";


export default {
    name: "catalog",
    components: {
        catalogSection,
    },
    mixins: [dynamicHead],
    data() {
        return {
            pageInfo: ''
        }
    },
    mounted() {
        // document.title = `${this.$t('catalog.title')}`
        document.getElementById('html').classList.remove('hide')
        this.getPageContent('categories').then(() => {
            this.pageInfo = this.pageContent
            this.setPageItem(this.pageContent)
        })

        this.setRequestFlag(true);
    },
    created() {

    },
    computed: {
        ...mapGetters({
            globalRequestsFlag: 'system/globalRequestsFlag',
            pageContent: 'setting/pageContent',
            pageLoading: 'setting/pageLoading'
        })
    },

    methods: {
        ...mapActions({
            getPageContent: 'setting/GET_PAGE_CONTENT'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',

        })
    }
}
