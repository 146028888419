import {mapActions, mapMutations, mapGetters} from "vuex";

export default {
    name: "catalog-section",
    data() {
        return {
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: ''},
                {src: 'catalog', title: this.$t('catalog.title'), slug: ''}
            ],
        }
    },
    created() {
        this.getCategories()
    },
    computed: {
        ...mapGetters({
            categoriesList: 'catalog/categoryList',
            categoriesIsLoading: 'catalog/categoriesIsLoading'
        })
    },

    methods: {
        ...mapActions({
            getCategories: 'catalog/GET_CATEGORY'
        }),
        ...mapMutations({})
    }
}
